import React, { useEffect, useState } from 'react'
import { startUpload } from '../hooks/uploadFile'
import { styled } from '@mui/material/styles';

import { Link, Container, Typography, Divider, Stack, Button, TextField, Checkbox, LinearProgress, Box, Autocomplete, Chip, Alert, Backdrop, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Swal from 'sweetalert2'

import styles from "../styles/documentsPage.module.scss";
import { Uppy } from '@uppy/core';
import { DragDrop, StatusBar } from '@uppy/react';
import Transloadit from '@uppy/transloadit';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import AwsS3 from "@uppy/aws-s3";

import Tus from '@uppy/tus';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatBytes } from '../utils/formatNumber'

import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import '@uppy/status-bar/dist/style.min.css';


const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));


const createUppy = (callback) => {
    // Adding to global `meta` will add it to every file.
    // Every Uppy instance needs a unique ID.
    const uppy = new Uppy({
        allowMultipleUploads: false,
        restrictions: {
            allowedFileTypes: ['.docx', '.doc', '.pdf', 'image/*', '.pptx', '.xlsx', '.csv']
        }
    })
    uppy.use(ThumbnailGenerator).use(AwsS3, {
        fields: [], // empty array 
        getUploadParameters(file) { // here we prepare our request to the server for the upload URL
            return fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/upload-document', { // we'll send the info asynchronously via fetch to our nodejs server endpoint, '/uploader' in this case
                method: 'POST', // all the examples I found via the Uppy site used 'PUT' and did not work
                headers: {
                    // 'content-type': 'application/x-www-form-urlencoded', 
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    "document_name": file.name,
                    "document_type": file.type
                })
            }).then((response) => {
                return response.json(); // return the server's response as a JSON promise
            }).then((data) => {
                console.log('>>>', data); // here you can have a look at the data the server sent back - get rid of this for production!
                return {
                    method: data.method, // here we send method, url, fields and headers to the AWS S3 bucket
                    url: data.url,
                    fields: data.fields,
                    headers: data.headers,
                };
            });
        },
    });
    return uppy
}

const AddDocumentForm = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const folderName = searchParams.get("from");

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])
    const [folders, setFolders] = useState([])
    const [foldersOptions, setFoldersOptions] = useState([]);
    const [documentPath, setDocumentPath] = useState("");

    const [documentURL, setDocumentURL] = useState("")


    const [documentMetaName, setDocumentMetaName] = useState("");
    const [documentMetaType, setDocumentMetaType] = useState("");
    const [documentMetaSize, setDocumentMetaSize] = useState(0);
    const [loading, setLoading] = useState(false);






    const navigate = useNavigate()

    const selectDocumentPath = (documentPath) => {
        setDocumentPath(documentPath)
    }

    const [uppy, setUppy] = useState(() => createUppy(selectDocumentPath));
    const [selecteFileDetails, setSelecteFileDetails] = useState({});
    const [fileUploadedSuccess, setFileUploadedSuccess] = useState({});

    let fromPage = searchParams.get("from");


    const fetchTagsOptions = async (search) => {
        const options = {
            headers: {
                'Content-Type': 'applications/json'
            }
        }
        if (search && search.length) {
            let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags?search_string=' + search, options)
            if (response.status == 200) {
                let respData = await response.json()
                setTagsOptions(respData.data.map((e) => e.tag))
            }
        }
        else {
            setTagsOptions([])
        }
    }

    const fetchFoldersOptions = async (search) => {
        const options = {
            headers: {
                'Content-Type': 'applications/json'
            }
        }
        if (search && search.length) {
            let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/folders?search_string=' + search, options)
            if (response.status == 200) {
                let respData = await response.json()
                setFoldersOptions(respData.data.map((e) => e.folder))
            }
        }
        else {
            setFoldersOptions([])
        }
    }
    const setDocumentData = (data) => {
        setFolders([data])

    }
    const handleBack = () => {
        let fromPage = searchParams.get("from");
        if (fromPage) {
            setDocumentData(fromPage)

        }

    }
    useEffect(() => {
        handleBack()
    }, [])

    useEffect(() => {
        const uppy = new Uppy({
            restrictions: {
                allowedFileTypes: ['.docx', '.doc', '.pdf', 'image/*', '.pptx', '.xlsx', '.csv']
            }
        })
        uppy.use(ThumbnailGenerator).use(AwsS3, {
            fields: [], // empty array 
            getUploadParameters(file) { // here we prepare our request to the server for the upload URL
                return fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/upload-document', { // we'll send the info asynchronously via fetch to our nodejs server endpoint, '/uploader' in this case
                    method: 'POST', // all the examples I found via the Uppy site used 'PUT' and did not work
                    headers: {
                        // 'content-type': 'application/x-www-form-urlencoded', 
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        "document_name": file.name,
                        "document_type": file.type
                    })
                }).then((response) => {
                    return response.json(); // return the server's response as a JSON promise
                }).then((data) => {
                    console.log('>>>', data); // here you can have a look at the data the server sent back - get rid of this for production

                    setDocumentPath(data.data.file_path)
                    return {
                        method: 'PUT', // here we send method, url, fields and headers to the AWS S3 bucket
                        url: data.data.signed_url
                    };
                });
            },
        });
        setUppy(uppy)
    }, [])

    uppy.on('file-added', (file) => {
        setSelecteFileDetails(file)
    });
    uppy.on('upload-success', (file) => {
        setFileUploadedSuccess(file.data)
        setDocumentMetaName(file.data.name)
        setDocumentMetaSize(file.data.size)
        setDocumentMetaType(file.data.type)
    });

    uppy.on('thumbnail:generated', (file, preview) => {
        console.log(file, preview)
    })

    const submitDocumentVideo = async () => {

        setLoading(true)
        try {

            const data = {
                title: title,
                description: description,
                tags: tags,
                folders: folders,
                document_meta_name: documentMetaName,
                document_meta_type: documentMetaType,
                document_meta_size: documentMetaSize,
                document_path: documentPath,
                document_url: documentURL,

            }

            const options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            }

            let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/document',
                options)
            if (response.status == 200) {
                const respData = await response.json()
                if (respData.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Document Added Successfully',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                        didClose: () => {
                            if (fromPage) {
                                navigate(`/dashboard/documents/${fromPage}`);
                            } else { navigate('/dashboard/documents') }
                        }
                    })
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Something went wrong !',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false,

                    })
                }
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong !',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }


        }
        catch (err) {

            console.log(err)
            Swal.fire({
                title: 'Error!',
                text: 'Do you want to continue',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            })
        }
        finally {
            setLoading(false)
        }

    }


    useEffect(() => {


    }, [])




    const checkDisable = () => {

        if (documentPath.length || documentURL.length) {
            return !title.length || !tags.length
        }
        else {
            return true
        }
    }



    const removeFile = (e) => {
        uppy.removeFile(selecteFileDetails.id)
        setSelecteFileDetails({})
    }
    return (
        <Container className={styles.addDocumentFormContainer}>
             <div className={styles.leftBlock} >
            <Stack spacing={3}>
                <DragDrop uppy={uppy} />
                <StatusBar uppy={uppy} />
                {!documentPath && selecteFileDetails && selecteFileDetails.name && <span> {

                    selecteFileDetails ? selecteFileDetails.name : ""} <button onClick={removeFile}>Remove</button></span>}

                {documentPath && fileUploadedSuccess && fileUploadedSuccess.name &&
                    <div>
                        <Alert severity="success">{fileUploadedSuccess.name} Document Uploaded Successfully !</Alert>
                    </div>
                }

            </Stack>
            </div>
            <b> ( Or )</b>
            <div className={styles.rightBlock}>
            <Stack spacing={5} mb={2} sx={{ textAlign: "center" }}>
               
                <TextField
                    name="document url" label="Document URL"
                    onChange={(event) => {
                        setDocumentURL(event.target.value);
                    }}
                />
            </Stack>

            <Stack spacing={3}>
                <TextField name="title"
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                    value={title}
                    label="Title" />

                <TextField multiline
                    maxRows={4} minRows={2}
                    name="description" label="Description"
                    value={description}

                    onChange={(event) => {
                        setDescription(event.target.value);
                    }}
                />


                <Autocomplete
                    multiple
                    id="tags-filled"
                    value={tags}
                    onChange={(event, newValue) => {
                        setTags(newValue.map((e) => e.toLowerCase()));
                    }}
                    options={tagsOptions}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}

                            onChange={(e) => {
                                fetchTagsOptions(e.target.value)
                            }}
                            label="Tags"
                            sx={{ background: "white" }}
                        />
                    )}
                />

                <Autocomplete
                    multiple
                    id="folders-filled"
                    value={folders}
                    onChange={(event, newValue) => {
                        setFolders(newValue.map((e) => e.toLowerCase()));
                    }}
                    options={foldersOptions}
                    freeSolo
                    renderTags={(value, getFoldersProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getFoldersProps({ index })} key={index} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}

                            onChange={(e) => {
                                fetchFoldersOptions(e.target.value)
                            }}
                            label="Folders"
                            sx={{ background: "white" }}
                        />
                    )}
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            </Stack>

            <LoadingButton className={styles.addDocumentButton} fullWidth size="large" type="submit" variant="contained" disabled={checkDisable()}//checkDisable()
                loading={loading}
                onClick={submitDocumentVideo}
            >
                Add Document
            </LoadingButton>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container >
    );
}

export default AddDocumentForm