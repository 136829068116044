import React, { useEffect, useState } from 'react'
import { startUpload } from '../hooks/uploadFile'
import { styled } from '@mui/material/styles';

import { Link, Container, Typography, Divider, Stack, Button, TextField, Checkbox, LinearProgress, Box, Autocomplete, Chip, Alert, Backdrop, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Swal from 'sweetalert2'
//scss styles
import styles from "../styles/videoPage.module.scss";
import { Uppy } from '@uppy/core';
import { DragDrop, StatusBar } from '@uppy/react';
import Transloadit from '@uppy/transloadit';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Tus from '@uppy/tus';
import { useNavigate } from 'react-router-dom';
import { formatBytes } from '../utils/formatNumber'

import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import '@uppy/status-bar/dist/style.min.css';
import { getThumbnailURL } from 'src/utils/files';


const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));


const createUppy = (callback) => {
    // Adding to global `meta` will add it to every file.
    // Every Uppy instance needs a unique ID.
    const uppy = new Uppy({
        restrictions: {
            allowedFileTypes: ['.mp4']
        }
    })
    uppy.use(ThumbnailGenerator).use(Tus, {
        endpoint: 'https://cloudflare-streams-tus-upload.test-blackbaze.workers.dev/upload',
        onAfterResponse: (req, res) => {
            // Get uid (video ID) here
            let uid = res.getHeader("Stream-Media-Id");
            callback(uid)
        }
    });
    return uppy
}

const AddVideoForm = () => {


    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])
    const [videoId, setVideoId] = useState("");
    const [videoURL, setVideoURL] = useState("")

    const [videoMetaName, setVideoMetaName] = useState("");
    const [videoMetaType, setVideoMetaType] = useState("");
    const [videoMetaSize, setVideoMetaSize] = useState(0);
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate()

    const selectVideoId = (id) => {
        setVideoId(id)
    }

    const [process, setProcess] = useState(0)
    const [buffer, setBuffer] = useState(0)
    const [fileUploadDone, setFileUploadDone] = useState(false)

    const [uppy, setUppy] = useState(() => createUppy(selectVideoId));
    const [selecteFileDetails, setSelecteFileDetails] = useState({});
    const [fileUploadedSuccess, setFileUploadedSuccess] = useState({});




    const fetchTagsOptions = async (search) => {
        const options = {
            headers: {
                'Content-Type': 'applications/json'
            }
        }
        if (search && search.length) {
            let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags?search_string=' + search, options)
            if (response.status == 200) {
                let respData = await response.json()
                setTagsOptions(respData.data.map((e) => e.tag))
            }
        }
        else {
            setTagsOptions([])
        }
    }

    useEffect(() => {
        const uppy = new Uppy({
            restrictions: {
                allowedFileTypes: ['video/*']
            }
        })
        uppy.use(ThumbnailGenerator).use(Tus, {
            chunkSize: 1024 * 1024 * 10,
            endpoint: 'https://cloudflare-streams-tus-upload.test-blackbaze.workers.dev/upload',
            onAfterResponse: (req, res) => {
                // Get uid (video ID) here
                let uid = res.getHeader("Stream-Media-Id");
                setVideoId(uid)
            },
            onSuccess: function (upload) {
                console.log('Download %s from %s', upload.file.name, upload.url)
            },
        });
        setUppy(uppy)

    }, [])

    uppy.on('file-added', (file) => {
        setSelecteFileDetails(file)
    });
    uppy.on('upload-success', (file) => {
        setFileUploadedSuccess(file.data)
        setVideoMetaName(file.data.name)
        setVideoMetaSize(file.data.size)
        setVideoMetaType(file.data.type)
    });

    uppy.on('thumbnail:generated', (file, preview) => {
        console.log(file, preview)
    })

    const submitAddVideo = async () => {

        setLoading(true)
        try {

            const data = {
                video_id: videoId,
                title: title,
                description: description,
                tags: tags,
                thumbnail: "",
                video_meta_name: videoMetaName,
                video_meta_type: videoMetaType,
                video_meta_size: videoMetaSize,
                video_url: videoURL
            }
            const options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            }

            let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/videos',
                options)
            if (response.status == 200) {
                const respData = await response.json()
                if (respData.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Video Added Successfully',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                        didClose: () => {
                            navigate('/dashboard/videos')
                        }
                    })
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Something went wrong !',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false,
                    })
                }
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong !',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }


        }
        catch (err) {

            console.log(err)
            Swal.fire({
                title: 'Error!',
                text: 'Do you want to continue',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            })
        }
        finally {
            setLoading(false)
        }

    }


    useEffect(() => {


    }, [])



    const checkDisable = () => {

        if (videoId.length || videoURL.length) {
            return !title.length || !tags.length
        }
        else {
            return true
        }
    }



    const removeFile = (e) => {
        uppy.removeFile(selecteFileDetails.id)
        setSelecteFileDetails({})
    }

    return (
        <Container className={styles.addVideoFormContainer} >
             <div className={styles.leftBlock} >
            {uppy && <Stack spacing={3}>
                <DragDrop uppy={uppy} />
                <StatusBar uppy={uppy} />
                {!videoId && selecteFileDetails && selecteFileDetails.name && <span> {

                    selecteFileDetails ? selecteFileDetails.name : ""} <button onClick={removeFile}>Remove</button></span>}

                {videoId && fileUploadedSuccess && fileUploadedSuccess.name &&
                    <div>
                        <div>
                            <img width="500" src={`https://customer-g09h5d11kclac9p1.cloudflarestream.com/${videoId}/thumbnails/thumbnail.jpg?time=8s&height=270`} />
                        </div>

                        <p>
                            <span style={{ marginLeft: "30px" }}> {fileUploadedSuccess.name}</span>
                            <span style={{ alignContent: "right", marginLeft: "50px" }}> {formatBytes(fileUploadedSuccess.size)}</span>
                        </p>

                        <Alert severity="success">Video Uploaded Successfully !</Alert>
                    </div>}

            </Stack>}
            </div>
            <b> ( Or )</b>
            <div className={styles.rightBlock}>
            <Stack spacing={5} mb={2} sx={{ textAlign: "center" }}>
              
                <TextField
                    name="video url" label="Video URL"
                    onChange={(event) => {
                        setVideoURL(event.target.value);
                    }}
                />

                {videoURL &&
                    <div>
                        <div>
                            <img width="100" src={getThumbnailURL(videoURL)} />
                        </div>
                    </div>}
            </Stack>
            <Stack spacing={3}>
                <TextField name="title"
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                    label="Title" />

                <TextField multiline
                    maxRows={4} minRows={2}
                    name="description" label="Description"
                    onChange={(event) => {
                        setDescription(event.target.value);
                    }}
                />


                <Autocomplete
                    multiple
                    id="tags-filled"
                    value={tags}
                    onChange={(event, newValue) => {
                        setTags(newValue.map((e) => e.toLowerCase()));
                    }}
                    options={tagsOptions}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}

                            onChange={(e) => {
                                fetchTagsOptions(e.target.value)
                            }}
                            label="Tags"
                            sx={{ background: "white" }}
                        />
                    )}
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            </Stack>

            <LoadingButton className={styles.addVideoButton} fullWidth size="large" type="submit" variant="contained" disabled={checkDisable()} //checkDisable()
                loading={loading}
                onClick={submitAddVideo}
            >
                Add Video
            </LoadingButton>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container >
    );
}

export default AddVideoForm