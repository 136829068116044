export function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


export function openURL(url) {
    var link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}



export function getThumbnailURL(url) {
    // example https://www.youtube.com/watch?v=8VrKlCaIokU
    if (url.indexOf('youtube.com') > -1) {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        const videoId = urlParams.get('v');
        return `http://img.youtube.com/vi/${videoId}/0.jpg`
    }
    return '/assets/icons/id_video.svg'
}
export function getYoutubeEmbededURL(url) {
    // example https://www.youtube.com/watch?v=8VrKlCaIokU
    if (url.indexOf('youtube.com') > -1) {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        const videoId = urlParams.get('v');
        return `https://www.youtube.com/embed/${videoId}`
    }
    return url
}