import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, Backdrop, CircularProgress, Chip } from '@mui/material';
// components
import Iconify from '../components/iconify';
import { VideoCard, BlogPostsSort } from '../sections/@dashboard/blog';
import { useState, useEffect } from 'react';
//styles scss
import styles from "../styles/watchVideo.module.scss";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField } from '@mui/material';
// components

// import stylesSearch from "../../../styles/watchVideo.module.scss";
import stylesSearch from "./../styles/watchVideo.module.scss";



// mock
// ----------------------------------------------------------------------
const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

// ----------------------------------------------------------------------


const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function WatchVideos() {

  const [videos, setVideos] = useState([])
  const [loading, setLoading] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    fetchVideos();
    getAllTagsData();
  }, [])

  WatchVideoSearch.propTypes = {
    tags: PropTypes.array.isRequired,
  };

  function WatchVideoSearch({ onTagsUpdate, updatedtags }) {


    const [tagsOptions, setTagsOptions] = useState([])
    // const [tags, setTags] = useState([])

    const fetchTagsOptions = async (query) => {
      const options = {
        headers: {
          'Content-Type': 'applications/json'
        }
      }

      if (query) {

        let res = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags?get_all=true')
        if (res.status == 200) {
          let responseData = await res.json()
          setTagsOptions(responseData.data.map((e) => e.tag))
        } else {
          let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags?search_string=' + query, options)
          if (response.status == 200) {
            let respData = await response.json()
            setTagsOptions(respData.data.map((e) => e.tag))
          }
        }
      }
    }

    const fetchTagsBySearch = async (e) => {
      let query = e.target.value
      if (query.length) {
        await fetchTagsOptions(query)
      }
    }
    const fetchTagsOption = async (query) => {
      const options = {
        headers: {
          'Content-Type': 'applications/json'
        }
      }

      let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags-of-videos?get_all=true')
      if (response.status == 200) {
        let respData = await response.json()
        setTagsOptions(respData.data.map((e) => e.tag))
      }
    }

    useEffect(() => {
      fetchTagsOption()
    }, [])

    return (

      <Autocomplete
        sx={{ width: 1080, fontSize: "10px", padding: "0" }}
        multiple
        limitTags={1}
        id="multiple-limit-tags"
        value={updatedtags}
        onChange={(event, newValue) => {
          // setTags(newValue.map((e) => e.toLowerCase()));
          onTagsUpdate(newValue)
        }}
        noOptionsText={'No results'}
        options={tagsOptions}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip sx={{ margin: "0", height: "22px" }} variant="outlined" label={option} {...getTagProps({ index })} key={index} />
          ))
        }
        renderInput={(params) => (
          <TextField
            className={stylesSearch.searchForm}
            {...params}
            placeholder="Search by tags..."
            onChange={fetchTagsBySearch}
            size="small"
          />
        )}
      />
    );
  }

  const fetchVideos = async (tags = "") => {
    try {

      setLoading(true)
      const options = {
        method: 'GET',
      }

      let url = 'https://api-customvideocms-com.test-blackbaze.workers.dev/videos'

      if (tags && tags.length) {
        url += '?tags=' + tags.join(",")
      }


      if (url.indexOf('?') == -1) {
        url += '?get_all=true'
      }
      else {
        url += '&get_all=true'
      }

      let response = await fetch(url, options)

      if (response.status == 200) {
        let respData = await response.json()
        if (respData.success) {
          setVideos(respData.data.data)
        }
      } else {
        if (response.success) {
          setVideos([])
        }
      }
    }
    catch (err) {
      console.log(err)
      setVideos([])
    }
    finally {
      setLoading(false)
    }
  }

  const fetchVideosByTags = (tags) => {
    setSelectedTags(tags);
    fetchVideos(tags);

  }
  useEffect(() => {
    fetchVideos(selectedTags);
  }, [])


  const getAllTagsData = async () => {
    try {
      let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags-of-videos?get_all=true')
      if (response.status == 200) {
        let respData = await response.json()
        setAllTags(respData.data)
      }
    } catch (err) {
    }
  }
  const selectTagChips = (clickedtag) => {

    let finalTags = selectedTags;
    let isSelected = finalTags.findIndex((e) => e == clickedtag)
    if (isSelected == -1) {
      finalTags.push(clickedtag);
    } else {
      let deletedTagList = finalTags.filter((e) => e != clickedtag);
      finalTags = deletedTagList
    }
    setSelectedTags(finalTags);
    fetchVideos(finalTags);
  }
  return (
    <>
      <Helmet>
        <title> Dashboard: Watch Videos | Video CMS </title>
      </Helmet>

      <Container className={styles.videoGrid}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Watch Videos
          </Typography>

          <Stack width={350} mb={1} direction="row" alignItems="center" justifyContent="center">
            <WatchVideoSearch onTagsUpdate={fetchVideosByTags} updatedtags={selectedTags} />
          </Stack>

          {tagsOpen ?
            <Button style={{ color: '#ff0000', background: "#fdedb3" }} onClick={() => setTagsOpen(!tagsOpen)}>
              Hide Tags<KeyboardArrowDownIcon />
            </Button> :
            <Button style={{ color: '#ff0000', background: "#fdedb3" }} onClick={() => setTagsOpen(!tagsOpen)}>
              Show All Tags<KeyboardArrowRightIcon />
            </Button>
          }

        </Stack>
        {tagsOpen ?
          <div className={styles.showTagsContainer} >
            <div style={{ height: "100px" }}>
              {allTags && allTags.map((item, index) => {
                let isSelected = selectedTags.findIndex((e) => e == item.tag)
                if (isSelected == -1) {
                  return (
                    <span key={item.id} style={{ padding: "2px" }}>
                      <Chip className={styles.showTags} label={item.tag} clickable onClick={() => selectTagChips(item.tag)} />
                    </span>
                  )
                }
                else {
                  return (
                    <Chip className={styles.slectedTag} key={item.id} label={item.tag} clickable onClick={() => selectTagChips(item.tag)} />
                  )
                }
              })}
            </div>
          </div> : ""}
        {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <WatchVideoSearch onTagsUpdate={fetchVideosByTags} />
        </Stack> */}

        <Grid container spacing={3} justifyContent={'center'} mt={5}>
          {videos.length ? videos.map((post, index) => (
            <VideoCard key={post.id} post={post} index={index} />
          )) : !loading ? <img style={{ margin: "1rem auto" }} src='/assets/no-data.svg' alt="no-data" />
            : ""}
        </Grid>
      </Container>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


    </>
  );
}
