import { Backdrop, Button, Card, CircularProgress, Container, IconButton, MenuItem, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react"
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { UserListHead } from "src/sections/@dashboard/user";
import { fDate } from "../utils/formatTime"
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Iconify from '../components/iconify';
import { downloadURI, openURL } from '../utils/files'
import { Label } from "@mui/icons-material";
import styles from "../styles/documentsPage.module.scss";
import ConfirmDeleteDialog from '../sections/@dashboard/blog/ConfirmDeleteDialog'

import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Swal from "sweetalert2";


const TABLE_HEAD = [
    { id: 'title', label: 'Title', alignRight: false, width: 330 },
    { id: 'description', label: 'Description', alignRight: false, width: 300 },
    // { id: 'folders', label: 'Folders', alignRight: false, width: 200 },
    { id: 'tags', label: 'Tags', alignRight: false, width: 350 },
    { id: 'created_at', label: 'Created On', alignRight: false, width: 250 },

    { id: '' },
];





export default function SingelFolderFiles() {

    const navigate = useNavigate()

    let query = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get('view');


    const [order, setOrder] = useState('asc');

    const [open, setOpen] = useState(null);

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filesOptions, setFilesOptions] = useState([])

    const [selectedRow, setSelectedRow] = useState({});
    const [loading, setLoading] = useState(false);
    const [showDeleteConfimation, setShowDeleteConfimation] = useState(false);

    useEffect(() => {
        fetchDocuments()
    }, [])

    const fetchDocuments = async () => {
        setLoading(true);


        const options = {
            method: 'GET',
        }
        setLoading(true);
        let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/documents?folders=' + query.folders, options)
        if (response.status == 200) {

            let responseData = await response.json();
            // if (!responseData.data.data.length) {
            //     navigate(`/dashboard/documents/watch-folders?view=${view}`)
            // }
            setFilesOptions(responseData.data.data)
            setLoading(false);
        }
        setLoading(false);

    }

    const downloadDocument = async (path, title, url) => {

        try {

            if (path && path.length > 0) {
                let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/download-document', { // we'll send the info asynchronously via fetch to our nodejs server endpoint, '/uploader' in this case
                    method: 'POST', // all the examples I found via the Uppy site used 'PUT' and did not work
                    headers: {
                        // 'content-type': 'application/x-www-form-urlencoded', 
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        "file_path": path
                    })
                })

                const respData = await response.json()

                downloadURI(respData.data.signed_url, title)
            }
            else if (url) {
                openURL(url, title)
            }
        }
        catch (err) {
            console.err(err)
        }


    }

    const deleteVideo = async (id) => {
        try {

            setLoading(true)
            const options = {
                method: 'DELETE',
            }

            let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/documents/' + id,
                options)

            if (response.status == 200) {

                Swal.fire({
                    title: 'Success!',
                    text: 'Document Deleted Successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                fetchDocuments();

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong !',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        }
        catch (err) {
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong !',
                icon: 'error',
                showConfirmButton: false,
                timer: 1000
            })
        }
        finally {
            setLoading(false)
        }
    }

    const goToEditDocument = () => {
        setOpen(null);
        navigate(`/dashboard/documents/${selectedRow.id}/edit?from=${query.folders}&view=${view}`)
    }

    const onDeleteVideo = () => {
        setOpen(null);
        setShowDeleteConfimation(true);
    }

    const handleOpenMenu = (event, row) => {
        setOpen(event.currentTarget);
        setSelectedRow(row)
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = filesOptions.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };





    return (
        <>

            <Container className={styles.dataTable}>
                {/* <div className={styles.navigationLinks}>
                    <Link href='/dashboard/documents'>Documents</Link>
                    <Link href='/dashboard/documents/watch-folders'>Folders</Link>
                </div> */}

                {/* <Button className={styles.folderButton} variant='contained' onClick={() => navigate('/dashboard/documents')}>
                    Documents
                </Button>
                <Button className={styles.folderButton} variant='contained' onClick={() => navigate('/dashboard/watch-folders')}>
                    Folders
                </Button> */}

                <Stack direction="row" mb={2} justifyContent={"space-between"} className={styles.folderView}>
                    <div>
                        {/* <Typography variant="h4">
                            Documents
                        </Typography> */}
                        <div role="presentation">
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="red" href={`/dashboard/documents?view=${view}`}>
                                    Documents
                                </Link>
                                <Link
                                    underline="hover"
                                    color="red"
                                    href={`/dashboard/documents/all-documents`}
                                >
                                    All Documents
                                </Link>
                                <Typography color="red">{query.folders}</Typography>
                            </Breadcrumbs>
                        </div>
                    </div>
                    <Button className={styles.newDocumentButton} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => navigate(`/dashboard/documents/add?from=${query.folders}&view=${view}`)}>
                        New Document
                    </Button>
                </Stack>
                {loading ?
                    ""
                    : <Card>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={filesOptions.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filesOptions.length ? filesOptions.map((row) => {
                                            const { id, title, tags, folders, description, status, document_path, createdAt,
                                                document_url } = row;
                                            const selectedUser = selected.indexOf(title) !== -1;

                                            return (
                                                <TableRow hover key={id} tabIndex={-1} >
                                                    <TableCell sx={{ width: "5%" }} >
                                                        <img alt={title} heigth="100" src="/assets/icons/ic_document.svg" />
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography variant="subtitle2">
                                                                <Link sx={{ cursor: "pointer" }} onClick={() => {
                                                                    downloadDocument(document_path, title, document_url)
                                                                }} >{title}</Link>
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{
                                                        description}</TableCell>

                                                    {/* <TableCell align="left">{
                                                    folders.map((folder, index) => {
                                                        return <Button className={styles.folderName} key={index} >{folder}</Button>
                                                    })
                                                }</TableCell> */}

                                                    <TableCell align="left">{
                                                        tags.map((tag, index) => {
                                                            return <Button className={styles.tagName} key={index} >{tag}</Button>
                                                        })
                                                    }</TableCell>

                                                    <TableCell align="left">
                                                        {fDate(createdAt, "dd MMM yyyy hh:mm a")}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <IconButton size="large" color="inherit" onClick={(e) => {
                                                            handleOpenMenu(e, row)
                                                        }}>
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }) : !loading ?
                                            (<div style={{ alignItems: "center", width: "100px", display: "none" }}>No Data</div>) : ""
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </Card>}
                {
                    showDeleteConfimation &&
                    <ConfirmDeleteDialog row={selectedRow} type={'document'} onDelete={(status) => {
                        setShowDeleteConfimation(false)
                        if (status) {
                            deleteVideo(selectedRow.id)
                        }
                    }}>

                    </ConfirmDeleteDialog>
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => {
                    downloadDocument(selectedRow.document_path, selectedRow.document_meta_name, selectedRow.document_url)
                }}>
                    <Iconify icon={'material-symbols:download'} sx={{ mr: 2 }} />
                    Download
                </MenuItem>
                <MenuItem onClick={goToEditDocument}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={onDeleteVideo}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>

            </Popover>

            {!filesOptions.length && !loading ?
                <img style={{ margin: "5rem auto" }} src='/assets/no-data.svg' alt="no-data" /> : ''}
        </>
    )
}