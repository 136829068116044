import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
// components
import Iconify from '../components/iconify';
import { PlayVideoCard } from '../sections/@dashboard/blog';
import { useState, useEffect } from 'react';
import styles from "../styles/videoPage.module.scss";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
// mock
// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function WatchVideos() {

  const [video, setVideo] = useState({})

  const [loading, setLoading] = useState(false);


  const navigate = useNavigate()
  let params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();



  const backToHome = () => {
    if (searchParams.get('from')) {
      navigate('/dashboard/videos');
    } else { navigate('/dashboard/watch-videos') }
  }

  useEffect(() => {
    fetchVideos()
  }, [])
  const fetchVideos = async () => {
    try {

      setLoading(true)
      const options = {
        method: 'GET',
      }

      let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/videos/' + params.id,
        options)

      if (response.status == 200) {
        let respData = await response.json()
        if (respData.success) {
          setVideo(respData.data)
        }
      } else {
        setVideo({})
      }


    }
    catch (err) {

      console.log(err)
      setVideo({})

    }
    finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Watch Videos | Video CMS </title>
      </Helmet>


      <Container>
        <Button className={styles.backButton} onClick={backToHome}> Back to Videos</Button>
        {video && video.title &&

          <Stack spacing={3}>


            {/* This is a video player scripts */}
            <PlayVideoCard key={video.id} post={video} />

            <Typography variant="h4" alignContent="center">
              {video.title}
            </Typography>


            <Typography alignContent="center">
              {video.tags.map((e, index) => {
                return <span key={index} style={{ marginRight: '10px', background: '#ffcb05' }}> # {e}</span>
              })}
            </Typography>
            <Typography alignContent="center">
              {video.description}
            </Typography>


          </Stack>

        }
      </Container>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


    </>
  );
}
