const getExpiryDate = () => {
    let theDate = new Date();
    theDate.setHours(theDate.getHours() + 5);
    return theDate.toISOString();
};



export const startUpload = (file, chunkSize, endpoint, onProgressCallBack) => {
    const options = {
        endpoint: endpoint,
        chunkSize: chunkSize,
        metadata: {
            expiry: getExpiryDate(),
            maxDurationSeconds: 3600,
            name: file.name,
        },
        onError(error) {
            console.log(error)
        },
        onSuccess() {
        },
        onProgress(bytesUploaded) {

            const progress = (bytesUploaded / file.size) * 100;
            onProgressCallBack(bytesUploaded, progress)

            alert(bytesUploaded)
            // const container = document.getElementById("progress-bar-container");
            // const progressBar = document.getElementById("progress-bar-fill");
            // const progressBarText = document.getElementById("progress-bar-percent");

            // container.style.display = "block";
            // progressBar.style.width = progress + "%";
            // progressBarText.innerHTML = Math.floor(progress) + "% Complete";
        },
    };

    let tus = window.tus
    let tusUpload = new tus.Upload(file, options);

    console.log(tusUpload)
    tusUpload.start();
}