import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  Link,
  Chip
} from '@mui/material';
//style scss 
import styles from "../styles/documentsPage.module.scss";
// import stylesTags from "../styles/watchVideo.module.scss";

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock

import ConfirmDeleteDialog from '../sections/@dashboard/blog/ConfirmDeleteDialog'

import Swal from 'sweetalert2'

import { useNavigate } from "react-router-dom";
import { fDate } from "../utils/formatTime"

import { downloadURI, openURL } from '../utils/files'
import { WatchVideoSearch } from '../sections/@dashboard/blog';
import uzCyrl from 'date-fns/locale/uz-Cyrl/index.js';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false, width: 180 },
  { id: 'description', label: 'Description', alignRight: false, width: 300 },
  { id: 'folders', label: 'Folders', alignRight: false, width: 200 },
  { id: 'tags', label: 'Tags', alignRight: false, width: 300 },
  { id: 'created_at', label: 'Created On', alignRight: false, width: 300 },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DocumentsPage() {

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [documentList, setDocumentList] = useState([]);
  const [showDeleteConfimation, setShowDeleteConfimation] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10)

  const [tagsOpen, setTagsOpen] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const goToEditDocument = () => {
    setOpen(null);
    navigate('/dashboard/documents/' + selectedRow.id + '/edit')
  }

  const onDeleteVideo = () => {
    setOpen(null);

    setShowDeleteConfimation(true)
  }

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedRow(row)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documentList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };


  useEffect(() => {
    fetchDocuments(page, limit);
  }, [])




  const fetchDocumentsByTags = async (tags) => {
    setSelectedTags(tags);
    await fetchDocuments(page, limit, tags)

  }

  const fetchDocuments = async (page, limit, tags = "") => {
    try {

      setLoading(true)
      const options = {
        method: 'GET',
      }


      let url = `https://api-customvideocms-com.test-blackbaze.workers.dev/documents?page=${page + 1}&limit=${limit}`

      if (tags && tags.length) {
        url += '&tags=' + tags.join(",")
      }
      let response = await fetch(url, options)

      if (response.status == 200) {
        let respData = await response.json();

        if (respData.success) {
          setDocumentList(respData.data.data);
          setTotalCount(respData.data.total);
        }
      } else {
        if (response.success) {
          setDocumentList([])
        }
      }
    }
    catch (err) {
      console.log(err)
      setDocumentList([])
    }
    finally {
      setLoading(false)
    }
  }

  const deleteVideo = async (id) => {
    try {

      setLoading(true)
      const options = {
        method: 'DELETE',
      }

      let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/documents/' + id,
        options)

      if (response.status == 200) {

        Swal.fire({
          title: 'Success!',
          text: 'Document Deleted Successfully',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        })

        fetchDocuments()

      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong !',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        })
      }
    }
    catch (err) {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong !',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      })
    }
    finally {
      setLoading(false)
    }
  }

  const downloadDocument = async (path, title, url) => {
    try {

      if (path && path.length > 0) {
        let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/download-document', { // we'll send the info asynchronously via fetch to our nodejs server endpoint, '/uploader' in this case
          method: 'POST', // all the examples I found via the Uppy site used 'PUT' and did not work
          headers: {
            // 'content-type': 'application/x-www-form-urlencoded', 
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            "file_path": path
          })
        })

        const respData = await response.json()

        downloadURI(respData.data.signed_url, title)
      }
      else if (url) {
        openURL(url, title)
      }
    }
    catch (err) {
      console.err(err)
    }


  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchDocuments(newPage, limit);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    fetchDocuments(0, parseInt(event.target.value, 10))
  };

  // const handleFilterByName = (event) => {
  //   setPage(0);
  //   setFilterName(event.target.value);
  // };


  const filteredUsers = applySortFilter(documentList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;


  const selectTagChips = (clickedtag) => {
    let finalTags = selectedTags;
    let isSelected = finalTags.findIndex((e) => e == clickedtag)
    if (isSelected == -1) {
      finalTags.push(clickedtag);
    } else {
      let deletedTagList = finalTags.filter((e) => e != clickedtag);
      finalTags = deletedTagList
    }
    setSelectedTags(finalTags);
    console.log(finalTags)
    fetchDocuments(page, limit, finalTags)
  }

  const allTagsList = (tags) => {
    setAllTags(tags);
  }

  return (
    <>
      <Helmet>
        <title> Documents | Video CMS </title>
      </Helmet>

      <Container className={styles.dataTable}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <div className={styles.navigationLinks}>

            {/* <Link href='/dashboard/documents'>Documents</Link> */}
            <Link href='/dashboard/documents'>Folders</Link>
          </div>
          <div className={styles.buttonGroup}>
            <Stack maxWidth={350} mb={1} direction="row" alignItems="center" justifyContent="space-between">
              <WatchVideoSearch onTagsUpdate={fetchDocumentsByTags} allTagsList={allTagsList} updatedTags={selectedTags} />
            </Stack>
            <Button className={styles.newDocumentButton} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => navigate('/dashboard/documents/add')}>
              New Document
            </Button>
            <div style={{ width: "150px" }}>
              {tagsOpen ?
                <Button style={{ color: '#ff0000', background: "#fdedb3" }} onClick={() => setTagsOpen(!tagsOpen)}>
                  Hide Tags<KeyboardArrowDownIcon />
                </Button> :
                <Button style={{ color: '#ff0000', background: "#fdedb3" }} onClick={() => setTagsOpen(!tagsOpen)}>
                  Show All Tags<KeyboardArrowRightIcon />
                </Button>
              }
            </div>
          </div>
        </Stack>
        {tagsOpen ?
          <div className={styles.showTagsContainer}>
            <div style={{ height: "100px" }}>
              {allTags && allTags.map((item, index) => {
                let isSelected = selectedTags.findIndex((e) => e == item)
                if (isSelected == -1) {
                  return (
                    <span key={item} style={{ padding: "2px" }}>
                      <Chip className={styles.showTags} label={item} clickable onClick={() => selectTagChips(item)} />
                    </span>
                  )
                }
                else {
                  return (
                    <Chip className={styles.slectedTag} key={item.id} label={item} clickable onClick={() => selectTagChips(item)} />
                  )
                }
              })}
            </div>
          </div> : ""}

        {loading ? "" : <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalCount}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers.slice(page * limit, page * limit + limit)?.map((row) => { */}
                  {filteredUsers?.map((row) => {
                    const { id, title, tags, folders, description, status, document_path, createdAt, document_url } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} >
                        <TableCell sx={{ width: "5%" }}>
                          <img alt={title} heigth="100" src="/assets/icons/ic_document.svg" />
                        </TableCell>

                        <TableCell component="th" scope="row" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2">
                              <Link sx={{ cursor: "pointer" }} onClick={() => {
                                downloadDocument(document_path, title, document_url)
                              }} >{title[0]?.toUpperCase() + title.slice(1, 30) + (title?.length > 30 ? '...' : "")}</Link>
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{description[0]?.toUpperCase() + description.slice(1, 50) + (description?.length > 50 ? '...' : "")}</TableCell>

                        <TableCell align="left">{
                          folders.map((folder, index) => {
                            return <Label key={index} sx={{ background: "#ED1C24", color: "#ffffff", marginLeft: "5px", marginTop: "5px" }}>{folder}</Label>
                          })
                        }</TableCell>

                        <TableCell align="left">
                          {tags.map((tag, index) => {
                            return <Label key={index} sx={{ background: "#ED1C24", color: "#ffffff", marginLeft: "5px", marginTop: "5px" }}>{tag}</Label>
                          })
                          }</TableCell>

                        <TableCell align="left">
                          {fDate(createdAt, "dd MMM yyyy hh:mm a")}
                        </TableCell>


                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => {
                            handleOpenMenu(e, row)
                          }}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {
                  isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                }
              </Table>
            </TableContainer>
          </Scrollbar>

          {filteredUsers.length ? <TablePagination
            className={styles.tablePagination}
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> : ""}
        </Card>}
        {!filteredUsers.length && !loading ?
          <img style={{ margin: "1rem auto" }} src='/assets/no-data.svg' alt="no-data" /> : ""}
      </Container >

      <Popover
        open={Boolean(open)}  
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => {
          downloadDocument(selectedRow.document_path, selectedRow.document_meta_name, selectedRow.document_url)
        }}>
          <Iconify icon={'material-symbols:download'} sx={{ mr: 2 }} />
          Download
        </MenuItem>
        <MenuItem onClick={goToEditDocument}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={onDeleteVideo}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {
        showDeleteConfimation &&
        <ConfirmDeleteDialog row={selectedRow} type={'document'} onDelete={(status) => {
          setShowDeleteConfimation(false)
          if (status) {
            deleteVideo(selectedRow.id)
          }
        }}>

        </ConfirmDeleteDialog>
      }

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
