import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//styles
import styles from "../../../styles/loginPage.module.scss";
// components
import Iconify from '../../../components/iconify';
import users from '../../../_mock/account'
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);


  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");


  const handleClick = () => {

    setWrongPassword(false)

    const user = users.find((user) => user.username == username && user.password == password)

    if (user) {
      navigate('/dashboard/videos');
    }
    else {
      setWrongPassword(true)
    }
  };

  return (
    <>
      <Stack spacing={3} className={styles.loginpageform}>
        <TextField  sx={{background:"#E8F0FE",fontSize:"2rem",marginTop:"1rem"}} name="usermane" label="Username" onChange={(e) => {
          setUsername(e.target.value)
        }} />

        <TextField
        sx={{background:"#E8F0FE",fontSize:"2rem"}}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton className={styles.viewIcon} onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify  icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {wrongPassword && <Alert severity="error">Invalid Credentials </Alert>}

        <LoadingButton className={styles.loginButton}  fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
          Login
        </LoadingButton>
      </Stack>



    </>
  );
}
