import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
//styles
import styles from "../styles/videoPage.module.scss";
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock

import ConfirmDeleteDialog from './../sections/@dashboard/blog/ConfirmDeleteDialog'

import Swal from 'sweetalert2'

import { Link, useNavigate } from "react-router-dom";
import { fDate } from "../utils/formatTime";
import { getThumbnailURL } from "../utils/files"
import VideoSearchByTag from './VideoSearchByTag';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false, width: 300 },
  { id: 'description', label: 'Description', alignRight: false, width: 400 },
  { id: 'tags', label: 'Tags', alignRight: false, width: 300 },
  { id: 'created_at', label: 'Created On', alignRight: false, width: 300 },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function VideoPage() {

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [searchString, setSearchString] = useState('');

  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [videoList, setVideoList] = useState([]);
  const [showDeleteConfimation, setShowDeleteConfimation] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [searchByTitle, setSearchByTitle] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');


  const goToEditVideo = () => {
    setOpen(null);
    navigate('/dashboard/videos/' + selectedRow.id + '/edit')
  }

  const onDeleteVideo = () => {
    setOpen(null);

    setShowDeleteConfimation(true)
  }

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedRow(row)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = videoList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };


  useEffect(() => {
    fetchVideos(page, limit, searchString);
  }, [])
  const fetchVideos = async (page, limit, search_string = '', tags = "") => {
    try {
      setLoading(true)
      const options = {
        method: 'GET',
      }
      let url = `https://api-customvideocms-com.test-blackbaze.workers.dev/videos?page=${page + 1}&limit=${limit}`;

      if (search_string) {
        url += `&title=${search_string}`;
      }
      if (tags) {
        url += `&tags=${tags}`
      }
      let response = await fetch(url, options)

      if (response.status == 200) {
        let respData = await response.json()

        if (respData.success) {
          setVideoList(respData.data.data)
          setTotalCount(respData.data.total)
        }
      } else {
        if (response.success) {
          setVideoList([])
        }
      }
    }
    catch (err) {
      console.log(err)
      setVideoList([])
    }
    finally {
      setLoading(false)
    }
  }

  const deleteVideo = async (id) => {
    try {

      setLoading(true)
      const options = {
        method: 'DELETE',
      }

      let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/videos/' + id,
        options)

      if (response.status == 200) {

        Swal.fire({
          title: 'Success!',
          text: 'Video Deleted Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
          showConfirmButton: false,
          timer: 1000
        })

        fetchVideos(page, limit, searchString)

      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong !',
          icon: 'error',
          confirmButtonText: 'Ok',
          showConfirmButton: false,
          timer: 1000
        })
      }
    }
    catch (err) {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong !',
        icon: 'error',
        confirmButtonText: 'Ok',
        showConfirmButton: false,
        timer: 1000
      })
    }
    finally {
      setLoading(false)
    }
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchVideos(newPage, limit, searchString)
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setLimit(parseInt(event.target.value, 10));
    fetchVideos(0, parseInt(event.target.value, 10), searchString);
  };

  useEffect(() => {
    const delay = 500;
    let timer;
    if (searchString) {
      timer = setTimeout(() => {
        setDebouncedSearchText(searchString);
      }, delay);
    } else { fetchVideos(0, limit); }

    return () => clearTimeout(timer);
  }, [searchString]);

  useEffect(() => {
    if (debouncedSearchText) {
      fetchVideos(0, limit, debouncedSearchText)
    }
  }, [debouncedSearchText]);


  const handleFilterByName = (event) => {
    setPage(0);
    setSearchString(event.target.value);
    // fetchVideos(page, limit, event.target.value);
  };


  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - videoList.length) : 0;

  const filteredUsers = applySortFilter(videoList, getComparator(order, orderBy), searchString);

  const isNotFound = !filteredUsers.length && !!searchString;

  const onTagsUpdate = (tag) => {
    if (tag.length) {
      fetchVideos(0, 10, '', tag.join())
    } else { fetchVideos(0, 10, '', '') }
  }

  const setSearchChange = () => {
    setSearchString('')
    setSearchByTitle(!searchByTitle);
    fetchVideos(0, limit);
  }

  return (
    <>
      <Helmet>
        <title> Videos | Video CMS </title>
      </Helmet>

      <Container className={styles.dataTable}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Videos
          </Typography>
          <div className={styles.buttonGroup}>
            <Button className={styles.newVedioButton} variant="contained" onClick={() => navigate('/dashboard/videos/upload-file')}>
              Import csv File
            </Button>
            <Stack sx={{ marginLeft: "20px" }}>
              {!searchByTitle
                ? <Button className={styles.newVedioButton} onClick={setSearchChange}>Search By Tags</Button> :
                <Button className={styles.newVedioButton} onClick={setSearchChange}>Search By Title</Button>}
            </Stack>
            <Stack>
              {!searchByTitle
                ? <UserListToolbar numSelected={selected.length}
                  searchString={searchString}
                  onFilterName={handleFilterByName} /> :
                <VideoSearchByTag onTagsUpdate={onTagsUpdate} />}
            </Stack>
            <Button className={styles.newVedioButton} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => navigate('/dashboard/videos/add')}>
              New Video
            </Button>
          </div>

        </Stack>
        {!loading ?
          <Card>


            <Scrollbar>
              <TableContainer sx={{ minWidth: "800" }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={videoList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {videoList.map((row) => {
                      const { id, title, tags, description, status, thumanailURL, video_id, video_url, createdAt } = row;
                      const selectedUser = selected.indexOf(title) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell sx={{ width: "120px" }}>
                            <Link to={`/dashboard/watch-videos/${row.id}/play?from=videos`}>

                              {video_id &&
                                <img style={{ height: "70px", width: "80px", objectFit: "cover" }} alt={title} src={`https://customer-g09h5d11kclac9p1.cloudflarestream.com/${video_id}/thumbnails/thumbnail.jpg?time=8s&height=270`} />}

                              {video_url &&
                                <img alt={title} src={getThumbnailURL(video_url)} />}
                            </Link>
                          </TableCell>

                          <TableCell component="th" scope="row" >
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Link to={`/dashboard/watch-videos/${row.id}/play?from=videos`} style={{ textDecoration: "none", color: "#007036", fontWeight: 'bold' }}>
                                {title[0]?.toUpperCase() + title.slice(1, 30) + (title?.length > 30 ? '...' : "")}
                              </Link>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{description[0]?.toUpperCase() + description.slice(1, 50) + (description?.length > 50 ? '...' : "")}</TableCell>

                          <TableCell className={styles.tableTags} align="left">{
                            tags.map((tag, index) => {
                              return <Label sx={{ color: "#ffffff", background: "#ED1C24", marginLeft: "4px", marginBottom: "4px", fontSize: "12px" }} key={index} color={'primary'}>{tag}</Label>
                            })
                          }</TableCell>

                          <TableCell align="left">
                            {fDate(createdAt, "dd MMM yyyy hh:mm a")}
                          </TableCell>


                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => {
                              handleOpenMenu(e, row)
                            }}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>

                  {/* {
                    isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{searchString}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  } */}
                </Table>
              </TableContainer>
            </Scrollbar>

            {videoList.length ? <TablePagination
              className={styles.tablePagination}
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={totalCount}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> : ""}
          </Card> : ""}

        {!videoList.length && !loading ?
          <img style={{margin:"5rem auto"}} src='/assets/no-data.svg' alt="no-data"/>
          : ""}

      </Container >

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => navigate(`/dashboard/watch-videos/${selectedRow.id}/play?from=videos`)}>
          <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
          View
        </MenuItem>
        <MenuItem onClick={goToEditVideo}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={onDeleteVideo}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {
        showDeleteConfimation &&
        <ConfirmDeleteDialog row={selectedRow} type={'video'}
          onDelete={(status) => {
            setShowDeleteConfimation(false)
            if (status) {
              deleteVideo(selectedRow.id)
            }
          }}>

        </ConfirmDeleteDialog>
      }

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
