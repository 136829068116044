import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField, Chip } from '@mui/material';
// components
import { useState, useEffect } from 'react';
import styles from "../styles/videoPage.module.scss";



// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '280px !important',
});

// ----------------------------------------------------------------------

VideoSearchByTag.propTypes = {
    tags: PropTypes.array.isRequired,
};


export default function VideoSearchByTag({ onTagsUpdate }) {


    const [tagsOptions, setTagsOptions] = useState([])
    const [tags, setTags] = useState([])

    const fetchTagsOptions = async (query) => {
        const options = {
            headers: {
                'Content-Type': 'applications/json'
            }
        }

        if (query) {

            let res = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags-of-videos?get_all=true')
            if (res.status == 200) {
                let responseData = await res.json()
                setTagsOptions(responseData.data.map((e) => e.tag))

            } else {
                let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags-of-videos?search_string=' + query, options)
                if (response.status == 200) {
                    let respData = await response.json()
                    setTagsOptions(respData.data.map((e) => e.tag))
                }
            }
        }
    }

    const fetchTagsBySearch = async (e) => {
        let query = e.target.value
        if (query.length) {
            await fetchTagsOptions(query)
        }
    }
    const fetchTagsOption = async (query) => {
        const options = {
            headers: {
                'Content-Type': 'applications/json'
            }
        }

        let response = await fetch('https://api-customvideocms-com.test-blackbaze.workers.dev/tags-of-videos?get_all=true')
        if (response.status == 200) {
            let respData = await response.json()
            setTagsOptions(respData.data.map((e) => e.tag))
        }
    }

    useEffect(() => {
        fetchTagsOption()
    }, [])

    return (

        <div style={{ padding: '15px' }}>
            <Autocomplete
                multiline
                limitTags={1}
                
                sx={{ width: "35vw", fontSize: "10px", padding: "0", marginLeft: "40px"}}
                multiple
                id="multiple-limit-tags"
                value={tags}
                onChange={(event, newValue) => {
                    setTags(newValue.map((e) => e.toLowerCase()));
                    onTagsUpdate(newValue)
                }}
                options={tagsOptions}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip sx={{ margin: "0", height: "22px" }} variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                    ))
                }
                noOptionsText={'No results'}
                renderInput={(params) => (
                    <TextField
                        
                        className={styles.searchForm}
                        {...params}
                        placeholder="Search by tags..."
                        onChange={fetchTagsBySearch}
                        size="small"
                    />
                )}
            />
        </div>
    );
}
