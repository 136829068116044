import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function MustDownloadDailog({ onClose, onDownload }) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        onClose(true)
    };
    const onDownloadVideo = () => {
        setOpen(false);
        onDownload(true)
    };



    React.useEffect(() => {

    }, [])




    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Action Required !
                </BootstrapDialogTitle> */}
                <DialogContent dividers>
                    <Typography gutterBottom>
                        The video stream is available only for 1 minute. Please
                        <span autoFocus onClick={() => { onDownloadVideo() }}><b style={{ color: '#ffcb03' }} hover='pointer'>{' download '}</b></span>
                        it to watch the complete video.
                        {/* You are not allowed to watch video more than 1 minute */}
                    </Typography>
                    {/* <Typography gutterBottom>
                        To watch complete video please download to your divice
                    </Typography> */}

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>
                        Not Required
                    </Button>
                    <Button autoFocus onClick={() => { onDownloadVideo() }}>
                        Get Download Link
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>
        </div>
    );
}